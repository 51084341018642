import request from '@/utils/request';

export interface CmdReq {
    robotId: string;
    state: number;
    cmd: string,
    remark: string,
    submitter: string,
}
export interface UploadCmdReqRes {
    msg: string;
    code: number;
    id: number;
}
/**
 * 新增指令记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function saveCmd(params: CmdReq): Promise<UploadCmdReqRes> {
    return request.post<UploadCmdReqRes>('/robotCmd/save', params);
}