



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UploadBagLogDialog extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  private tipName!: string; //提示
  @Prop({
    type: Boolean,
    default: false,
  })
  public show!: boolean; 
  private currentValue: string = ""; //当前值
  private result: string[] = []; //复选框结果
  private clickConfirm () {
    this.$emit("clickConfirm", { result: this.result, interception_time: this.currentValue })
    //this.show = false;
  }

  private clickCancel () {
    this.$emit("clickCancel");
    //this.show = false;
  }
}
